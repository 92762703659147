<template>
  <div>
    <!-- <v-form lazy-validation ref="coupon"> -->
    <!-- <v-row>
        <v-col cols="12" sm="12" md="12">
          
        </v-col>
      </v-row> -->
    <v-row>
      <v-col cols="12" md="6">
        <p>Nombre del cupón</p>
        <v-text-field
          rounded
          outlined
          :rules="[rules.required]"
          placeholder="Nombre para identificar el cupón"
          v-model.trim="coupon.name"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <p>Etiqueta en el App</p>
        <v-text-field
          rounded
          outlined
          :rules="[rules.required]"
          placeholder="Ingrese la etiqueta a mostrar en el App"
          v-model.trim="coupon.displayName"
          hint="Texto a mostrar en la aplicación al seleccionar el método de pago."
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="12">
        <p>Descripción</p>
        <v-text-field
          rounded
          placeholder="Descripción del cupón"
          v-model="coupon.description"
          outlined
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <p>Fecha que inicia</p>
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="true"
          :return-value.sync="date[0]"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              rounded
              :rules="[rules.required]"
              outlined
              v-on="on"
              placeholder="Fecha de inicio del cupón"
              v-model="date[0]"
            />
          </template>
          <v-date-picker
            v-model="date[0]"
            no-title
            scrollable
            @click:date="setDate1"
            :min="today"
          >
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="6">
        <p>Fecha que termina</p>
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="true"
          :return-value.sync="date[1]"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              outlined
              rounded
              v-on="on"
              :rules="[rules.required]"
              placeholder="Fecha que termina"
              v-model="date[1]"
            />
          </template>
          <v-date-picker
            v-model="date[1]"
            no-title
            scrollable
            @click:date="setDate2"
            :min="today"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row align="end">
      <v-col cols="12" md="6">
        <p>Tipo de cupón</p>
        <v-select
          v-model="coupon.type"
          :items="couponType"
          placeholder="Seleccione tipo de descuento"
          outlined
          :rules="[rules.required]"
          dense
          item-value="value"
          item-text="text"
          rounded
        />
      </v-col>

      <v-col cols="12" md="6">
        <p>Tipo de descuento</p>
        <v-select
          v-model="coupon.discountType"
          :items="discountType"
          placeholder="Seleccione tipo de descuento"
          outlined
          :rules="[rules.required]"
          dense
          item-value="value"
          item-text="text"
          rounded
        />
      </v-col>

      <v-col cols="12" md="6">
        <p>Monto del descuento</p>
        <v-text-field
          placeholder="Ejemplo : 25"
          rounded
          :rules="[rules.min]"
          @keydown="isNumber($event)"
          v-model.number="coupon.amount"
          outlined
        />
      </v-col>

      <v-col v-if="coupon.discountType == 'percent'" cols="12" md="6">
        <p>Monto máximo de descuento por cupón</p>
        <v-text-field
          placeholder="Monto máximo de descuento a aplicar por el cupón"
          rounded
          @keydown="isNumber($event)"
          v-model.number="coupon.maximumDiscount"
          outlined
        >
          <template v-slot:append-outer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">fas fa-question-circle</v-icon>
              </template>
              Este valor representa el máximo descuento que se podrá aplicar en
              una orden. Dejar vacío para no aplicar esta validación.
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <p>Monto mínimo de consumo</p>
        <v-text-field
          placeholder="Monto mínimo de la orden"
          rounded
          @keydown="isNumber($event)"
          v-model.number="coupon.minimumSale"
          outlined
        >
          <template v-slot:append-outer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">fas fa-question-circle</v-icon>
              </template>
              El cupón aplica si el subtotal de la orden es mayor a este valor.
              Dejar vacío para no aplicar esta validación
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <p>Cantidad disponibles para reclamar</p>
        <v-text-field
          rounded
          :rules="[rules.min]"
          @keydown="isNumber($event)"
          outlined
          placeholder="Cantidad disponibles para reclamar"
          v-model.number="coupon.inventory"
        />
      </v-col>

      <v-col cols="12" md="6">
        <p>Cantidad de usos por usuario</p>
        <v-text-field
          rounded
          :rules="[rules.min]"
          outlined
          @keydown="isNumber($event)"
          placeholder="Número de veces que el usuario podrá usar el cupón"
          v-model.number="coupon.usageAllowedByUser"
        />
      </v-col>

      <v-col cols="12" md="6">
        <v-switch
          v-model="coupon.showBadgeInApp"
          label="Mostrar etiqueta en el App"
        ></v-switch>
      </v-col>

      <v-col cols="12" md="6">
        <v-switch
          v-model="coupon.applyDiscount"
          label="Aplicar descuento en checkout"
        ></v-switch>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import randomstring from "randomstring";
import { db } from "@/firebase";

export default {
  name: "coupon-general",
  props: ["coupon", "date"],
  data() {
    return {
      menu1: false,
      menu2: false,
      couponType: [
        { text: "Subtotal", value: "subtotal" },
        { text: "Total", value: "total" },
        { text: "Envío", value: "shipping" },
      ],
      discountType: [
        { text: "Fijo", value: "amount" },
        { text: "Porcentual", value: "percent" },
      ],
      rules: {
        required: (value) => !!value || "Obligatorio",
        min: (v) => (v && v > 0) || "Debe ingresar un valor numerico mayor a 0",
        minNegative: (v) =>
          (v && v > -1) || "Debe ingresar un valor mayor o igual a 0",
      },
      // target: [
      //   { text: "Aplica a todo", value: "none" },
      //   { text: "Aplica a comercio", value: "businesses" },
      //   { text: "Aplica a categories", value: "categories" },
      //   { text: "Aplica a usuarios", value: "users" },
      // ],
      paymentGateways: [],
      generatingCode: false,
      // couponT: {
      //   minimumSale: 0,
      //   code: "",
      // },
    };
  },
  computed: {
    today() {
      return moment(new Date()).format("YYYY-MM-DD");
    },
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    formatCode(e) {
      // console.log(e);
      this.coupon.code = e.toUpperCase();
    },

    setDate1(date) {
      this.$refs.menu1.save(date);
    },
    setDate2(date) {
      this.$refs.menu2.save(date);
    },
    async generateCode() {
      let code = `${
        this.coupon.name.replace(/\s+/g, "").toUpperCase().substring(0, 15) ||
        "HN"
      }-${randomstring.generate(4).toUpperCase()}`;
      this.generatingCode = true;

      let result = await db
        .collection("coupons4")
        .where("code", "==", code)
        .get();
      if (result.empty) {
        this.coupon.code = code;
        this.generatingCode = false;
      } else {
        this.generateCode();
      }
    },
  },
  mounted() {
    // this.$binding(
    //   "paymentGateways",
    //   db
    //     .collection("paymentGateways")
    //     .where("deleted", "==", false)
    //     .where("countryId", "==", this.country)
    //     .where("active", "==", true)
    //     .orderBy("name")
    // );
  },
};
</script>


<style scoped>
.divider {
  margin-top: 20px;
  margin-bottom: 20px;
}

.fa-random {
  cursor: pointer;
  /* background-color: gray; */
  /* color: white; */
  font-size: 25px;
  color: gray;
  /* padding: 10px; */
}
</style>