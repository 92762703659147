<template>
  <div id="add-edit-coupon">
    <v-card class="px-2">
      <v-card-title>
        <span class="headline"> Nuevo cupón </span>

        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab key="general"> General </v-tab>
        <v-tab key="bins"> Bins </v-tab>
        <v-tab key="categories"> Categorías </v-tab>
        <v-tab key="businesses"> Comercios </v-tab>
        <v-tab key="users"> Usuarios </v-tab>
        <!-- <v-tab key="cards"> Bancos </v-tab> -->
      </v-tabs>

      <v-card-text>
        <v-container>
          <v-snackbar top :timeout="3000" v-model="snackbar">
            {{ snackbarText }}
          </v-snackbar>
          <v-overlay class="loading-center" :value="saving">
            <lottie
              :options="defaultOptions"
              :height="300"
              :width="400"
              v-on:animCreated="handleAnimation"
            />
          </v-overlay>

          <v-tabs-items v-model="tab">
            <v-tab-item key="general">
              <General :date="date" :coupon="coupon" />
            </v-tab-item>

            <v-tab-item key="bins">
              <Bins :item="coupon" />
            </v-tab-item>

            <v-tab-item key="categories">
              <Categories :item="coupon" />
            </v-tab-item>

            <v-tab-item key="businesses">
              <Businesses :item="coupon" />
            </v-tab-item>

            <v-tab-item key="users">
              <Users :item="coupon" />
            </v-tab-item>
          </v-tabs-items>

          <v-row class="pa-0 ma-0 mt-8">
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="6">
              <v-row align="center" justify="start">
                <v-switch
                  v-model="coupon.active"
                  label="Cupón activo"
                ></v-switch>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row align="center" justify="end">
                <v-btn
                  @click="submit"
                  :loading="loading"
                  class="mt-4"
                  color="primary"
                >
                  Guardar
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";

import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

import Categories from "./sections/Categories";
import Businesses from "./sections/Businesses";
import Users from "./sections/Users";

import { db, fb } from "@/firebase";
import { mapState } from "vuex";

import { validationMixin } from "vuelidate";

import { required, minValue, between } from "vuelidate/lib/validators";

import General from "./sections/general.vue";
import Cards from "./sections/creditCards.vue";
import Bins from "./sections/bins.vue";

// var randomstring = require("randomstring");

export default {
  name: "AddEditCoupon",
  components: {
    lottie: Lottie,
    Categories,
    Businesses,
    Users,
    General,
    Cards,
    Bins,
  },
  mixins: [lottieSettings, validationMixin],
  props: {
    // type: {
    //   type: String,
    //   required: true,
    // },
    // city: {
    //   type: String,
    //   required: true,
    // },
    // country: {
    //   type: String,
    //   required: true,
    // },
    item: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      tab: null,
      loading: false,
      saving: false,
      snackbarText: "",
      snackbar: false,
      date: [],
      coupon: {
        active: false,
        amount: "",
        applyDiscount: true,
        inventory: "",
        businesses: [],
        categories: [],
        showBadgeInApp: true,
        claimed: 0,
        createdAt: new Date(),
        createdBy: "",
        deleted: false,
        description: "",
        disclaimer: "",
        from: "",
        maximumDiscount: "",
        minimumSale: "",
        name: "",
        to: "",
        type: "",
        used: 0,
        usageAllowedByUser: 1,
        users: [],
        usedAt: [],
        bins: [],
      },
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  validations: {
    coupon: {
      inventory: { required, minValue: minValue(1) },
      name: { required },
      type: { required },
      code: { required },
      amount: {
        required,
        minValue: minValue(1),
      },
      minimumSale: { required, minValue: minValue(0) },
      maximumSale: { required, minValue: minValue(0) },
      coveredByOcho: { between: between(0, 100) },
      usageAllowedByUser: { required, minValue: minValue(1) },
      maximumDiscount: { required, minValue: minValue(1) },
      discountType: { required },
    },
  },

  methods: {
    async submit() {
      this.$v.$touch();
      let validationResult = await this.validate();

      if (validationResult) {
        this.loading = true;
        this.coupon.from = this.generateTimestamp(this.date[0], "start");
        this.coupon.to = this.generateTimestamp(this.date[1], "end");
        this.coupon.createdAt = new Date();
        this.coupon.createdBy = this.user[".key"];
        this.coupon.bins = this.coupon.bins.map((item) => {
          return Number(item.bin);
        });

        this.coupon.isFixed =
          this.coupon.discountType == "amount" ? true : false;

        delete this.coupon.discountType;

        this.coupon.maximumDiscount = this.coupon.maximumDiscount
          ? this.coupon.maximumDiscount
          : 0;

        this.coupon.minimumSale = this.coupon.minimumSale
          ? this.coupon.minimumSale
          : 0;

        db.collection("couponsBins")
          .add(this.coupon)
          .then(() => {
            this.loading = false;
            this.$emit("success", "Cupón agregado correctamente.");
          })
          .catch(() => {
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
            this.loading = false;
          });
      }
    },

    generateTimestamp(data, flag) {
      let date = data.split("-");
      return fb.firestore.Timestamp.fromDate(
        new Date(
          parseInt(date[0]),
          parseInt(date[1]) - 1,
          parseInt(date[2]),
          parseInt(`${flag == "start" ? "01" : "23"}`),
          parseInt(`${flag == "start" ? "00" : "59"}`),
          parseInt("00")
        )
      );
    },

    validate() {
      return new Promise(async (resolve) => {
        if (this.$v.coupon.name.$invalid) {
          this.showSnackBar("Debe ingresar el nombre del cupón");
          return resolve(false);
        }

        if (this.$v.coupon.type.$invalid) {
          this.showSnackBar("Debe seleccionar el tipo de cupón");
          return resolve(false);
        }

        if (this.$v.coupon.discountType.$invalid) {
          this.showSnackBar("Ingrese el tipo de descuento a aplicar al cupón");
          return resolve(false);
        }

        if (this.$v.coupon.amount.$invalid) {
          this.showSnackBar(
            "El monto de descuento del cupón debe ser mayor a cero."
          );
          return resolve(false);
        }

        if (this.$v.coupon.amount.$invalid) {
          this.showSnackBar(
            "El monto del descuento debe ser un número mayor a cero."
          );
          return resolve(false);
        }

        if (this.$v.coupon.inventory.$invalid) {
          this.showSnackBar("Debe ingresar la cantidad de cupones disponibles");
          return resolve(false);
        }

        if (this.$v.coupon.usageAllowedByUser.$invalid) {
          this.showSnackBar("Usos por usuarios deber ser mínimo 1");
          return resolve(false);
        }

        if (!this.date[0] || !this.date[1]) {
          this.showSnackBar("Debe ingresar fecha inicial y fecha final");
          return resolve(false);
        }

        if (
          Number(this.date[0].replaceAll("-", "")) >
          Number(this.date[1].replaceAll("-", ""))
        ) {
          this.showSnackBar(
            "La fecha final debe ser mayor que la fecha inicial"
          );
          return resolve(false);
        }

        return resolve(true);
      });
    },

    showSnackBar(message) {
      this.snackbarText = message;
      this.snackbar = true;
    },
  },
};
</script>
