<template>
  <v-container fluid class="px-2">
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-row class="pa-0 ma-0">
      <v-col cols="6">
        <h1>Bines</h1>
      </v-col>
      <v-col cols="6" style="display: flex; justify-content: flex-end">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="rgba(0, 0, 0, 0.6)"
              fab
              v-on="on"
              class="ml-2"
              dark
              small
              :elevation="0"
              :loading="loading"
              @click="reportsDialog = true"
            >
              <v-icon x-large dark>fa-file-excel</v-icon>
            </v-btn>
          </template>
          <span>Exportar .xlsx</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="py-0 mb-6">
        <v-row justify="end">
          <v-btn
            color="primary"
            class="ma-2 white--text"
            :loading="loading"
            @click="newCoupon"
          >
            <v-icon right dark class="mr-3">fas fa-plus</v-icon>
            Crear nuevo cupón
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="getCoupons"
          :items-per-page="10"
          :loading="loading"
          :search="$store.state.search"
          :sort-desc="true"
          item-key=".key"
          sort-by="createdAt"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div class="inner-lottie">
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>

          <template v-slot:[`item.bin`]="{ item }">
            {{ item.bins.join() }}
          </template>

          <template v-slot:[`item.from`]="{ item }">
            {{ item.from | filterDate }}
          </template>

          <template v-slot:[`item.to`]="{ item }">
            {{ item.to | filterDate }}
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            <p class="mt-4" v-if="item.isFixed">{{ item.amount | currency }}</p>
            <p class="mt-4" v-else>{{ `${item.amount}%` }}</p>
          </template>

          <template v-slot:[`item.duration`]="{ item }">
            {{ (item.to - item.from) | filterDuration }}
          </template>

          <template v-slot:[`item.type`]="{ item }">
            {{ item.type | filterCouponType }}
          </template>

          <template v-slot:[`item.isFixed`]="{ item }">
            {{ item.isFixed ? "Fijo" : "Porcental" }}
          </template>

          <template v-slot:[`item.categories`]="{ item }">
            <span v-if="item.categories">
              {{ item.categories.length }}
              <i
                v-if="item.categories.length > 0"
                @click="handleModalSummary('categories', item.categories)"
                class="fas fa-eye ml-3"
                style="color: gray; cursor: pointer"
              ></i>
            </span>
          </template>

          <template v-slot:[`item.businesses`]="{ item }">
            <span v-if="item.businesses">
              {{ item.businesses.length }}
              <i
                v-if="item.businesses.length > 0"
                @click="handleModalSummary('businesses', item.businesses)"
                class="fas fa-eye ml-3"
                style="color: gray; cursor: pointer"
              ></i>
            </span>
          </template>

          <template v-slot:[`item.available`]="{ item }">
            {{ item.inventory - item.claimed }}
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { active: item.active },
                  item['.key'],
                  item
                )
              "
              v-model="item.active"
            ></v-switch>
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="showInformation(item)"
                  class="mx-1"
                  fab
                  v-on="on"
                  dark
                  x-small
                  :elevation="0"
                  color="#5774a8"
                >
                  <v-icon dark>fa-info</v-icon>
                </v-btn>
              </template>
              <span>Información</span>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="downloadReport(item)"
                  class="mx-1"
                  fab
                  v-on="on"
                  dark
                  x-small
                  :elevation="0"
                  color="green"
                >
                  <v-icon dark>fa-file-excel</v-icon>
                </v-btn>
              </template>
              <span>Reporte</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog persistent v-model="addDialog" max-width="1000px">
      <add-edit-coupon
        @cancel="addDialog = false"
        @success="handleSuccess"
      ></add-edit-coupon>
    </v-dialog>

    <v-dialog
      persistent
      v-model="informationDialog"
      v-if="informationDialog"
      max-width="80%"
    >
      <Info :coupon="selectedCoupon" @cancel="informationDialog = false"></Info>
    </v-dialog>

    <v-dialog
      persistent
      v-model="reportsDialog"
      v-if="reportsDialog"
      max-width="600"
    >
      <report @cancel="reportsDialog = false"></report>
    </v-dialog>
  </v-container>
</template>

<script>
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import { db, fb } from "@/firebase";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import AddEditCoupon from "./AddEditCoupon";
import Info from "./Information";
import report from "./report";
import axios from "axios";

export default {
  name: "coupons-bin",
  components: {
    lottie: Lottie,
    AddEditCoupon,
    report,
    Info,
  },
  mixins: [lottieSettings],
  data() {
    return {
      loading: true,
      snackbar: false,
      snackbarText: "",
      coupons: [],
      users: [],
      addDialog: false,
      reportsDialog: false,
      selectedCoupon: null,
      informationDialog: false,

      headers: [
        {
          text: "Nombre",
          value: "name",
          width: "300px",
          class: "nowrap",
        },

        {
          text: "Inicia",
          value: "from",

          class: "nowrap",
        },
        {
          text: "Termina",
          value: "to",

          class: "nowrap",
        },

        {
          text: "Aplica a ",
          value: "type",
          width: "100px",
          class: "nowrap",
        },
        {
          text: "Beneficio",
          value: "isFixed",
          class: "nowrap",
        },
        {
          text: "Monto",
          value: "amount",
          class: "nowrap",
        },

        {
          text: "Inventario",
          value: "inventory",
          class: "nowrap",
        },
        {
          text: "Reclamados",
          value: "claimed",
          class: "nowrap",
        },
        {
          text: "Disponibles",
          value: "available",
          class: "nowrap",
        },

        {
          text: "Usos",
          value: "used",
          class: "nowrap",
        },

        {
          text: "Activo",
          value: "active",
          class: "nowrap",
        },
        {
          text: "Creado por",
          value: "createdByName",
          class: "nowrap",
          width: "150px",
        },
        {
          value: "options",
          class: "nowrap",
          width: "150px",
          align: "end",
        },
      ],
    };
  },

  async mounted() {
    this.$store.commit("setSearchTerm", "cupones");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;

    await this.$binding(
      "coupons",
      db.collection("couponsBins").where("deleted", "==", false)
    );

    await this.$binding("users", db.collection("appUsers"));

    this.loading = false;
  },

  computed: {
    getCoupons() {
      return this.coupons.map((e) => {
        let creator = this.users.find((u) => u[".key"] == e.createdBy);
        e.createdByName = creator ? creator.name : "";
        return e;
      });
    },
  },
  methods: {
    showInformation(item) {
      this.selectedCoupon = item;
      this.informationDialog = true;
    },
    getSectionToken() {
      return new Promise((resolve, reject) => {
        fb.auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            resolve(token);
          });
      });
    },
    async downloadReport(item) {
      this.loading = true;

      let token = await this.getSectionToken();

      let data = {
        code: item[".key"],
      };

      axios
        .post(
          `${process.env.VUE_APP_FUNCTIONS_URL}/callableCouponsBinesReport`,
          // "http://127.0.0.1:5001/el-ocho-dev/us-central1/callableCouponsBinesReport",
          data,
          { headers: { Authorization: `Basic ${token}` }, responseType: "blob" }
        )
        .then((response) => {
          this.loading = false;
          const { data, headers } = response;
          const fileName = `reporte.xlsx`;

          const blob = new Blob([data], { type: headers["content-type"] });
          let dom = document.createElement("a");
          let url = window.URL.createObjectURL(blob);
          dom.href = url;
          dom.download = decodeURI(fileName);
          dom.style.display = "none";
          document.body.appendChild(dom);
          dom.click();
          dom.parentNode.removeChild(dom);
          window.URL.revokeObjectURL(url);
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },
    switchControlChanged(data, couponId, coupon) {
      if (data && couponId) {
        this.loading = true;
        db.collection("couponsBins")
          .doc(couponId)
          .update({ active: data.active })
          .then(() => {
            this.loading = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
          })
          .catch(() => {
            this.loading = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;

            coupon.active = !coupon.active;
          });
      }
    },
    newCoupon() {
      this.addDialog = true;
    },

    handleSuccess(data) {
      this.snackbarText = data;
      this.snackbar = true;
      this.addDialog = false;
    },
    handleModalSummary(collecton, array) {},
  },

  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY");
    },
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },
    filterDuration(miliseconds) {
      return `${Math.round(miliseconds / 86400)} días`;
    },
    filterCouponType(type) {
      switch (type) {
        case "shipping":
          return "Envío";
        case "subtotal":
          return "Subtotal";
        case "total":
          return "Total";
      }
    },
    filterApplyDiscountTo(type) {
      switch (type) {
        case "subtotal":
          return "Subtotal";
        case "total":
          return "Total";
        case "shipping":
          return "Envio";
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped>
.inner-lottie {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
