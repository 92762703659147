<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-row>
      <v-col cols="12" md="6">
        <v-select
          v-model="selectedBank"
          :items="sortedBanks"
          placeholder="Seleccione un banco"
          outlined
          item-text="name"
          @change="getCards"
          :disabled="loading"
          item-value=".key"
          rounded
        />
      </v-col>

      <v-col cols="12" md="3">
        <v-btn
          @click="openFileExplorer"
          :loading="loading"
          block
          class="save-btn"
          color="primary"
        >
          Cargar excel
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-text-field
          hide-details
          rounded
          outlined
          placeholder="Buscar bin"
          class="mt-5"
          v-model="search"
        />
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-data-table
          :headers="headers"
          :items="item.bins"
          :items-per-page="10"
          :loading="loading"
          :sort-desc="true"
          item-key=".key"
          :search="search"
          :single-expand="true"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:[`item.options`]="{ item }">
            <v-btn
              @click="removeBin(item)"
              small
              class="ml-3 white--text"
              color="red darken-4"
            >
              Eliminar
            </v-btn>
          </template>
        </v-data-table>
        <input
          @change="setExcelFile"
          ref="input"
          id="input"
          class="input"
          accept=".xlsx"
          type="file"
        />
      </v-col>
    </v-row>

    <v-dialog
      max-width="550"
      v-model="matchDialog"
      v-if="matchDialog"
      persistent
    >
      <match-fields
        @cancel="matchDialog = false"
        :file="file"
        @success="processFile"
      />
    </v-dialog>
  </div>
</template>

<script>
import matchFields from "./match-fields";
import readXlsxFile from "read-excel-file";
import { db } from "@/firebase";

export default {
  props: ["item"],
  components: {
    matchFields,
  },
  data() {
    return {
      user: [],
      headers: [
        { text: "Bin", value: "bin" },
        { text: "Marca", value: "brand" },
        { text: "Tipo", value: "type" },
        { text: "Producto", value: "product" },
        { value: "options", align: "end" },
      ],

      snackbar: false,
      snackbarText: "",
      bin: "",
      loading: false,

      matchDialog: false,
      file: null,
      search: "",
      selectedBank: "",
      banks: [],
      cards: [],
    };
  },
  computed: {
    sortedBanks() {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }

      return this.banks.sort(compare);
    },
  },
  methods: {
    async getCards(id) {
      this.cards = [];
      this.loading = true;

      await this.$binding(
        "cards",
        db.collection("banks").doc(id).collection("cards")
      );

      this.item.bins = this.cards;

      this.loading = false;
    },

    openFileExplorer() {
      this.file = null;
      this.$refs.input.click();
    },
    processFile(e) {
      let binR = e.find((item) => item.value == "bin");
      let binPosition = binR.selectedColumn;

      readXlsxFile(this.file, { sheet: 1 }).then(async (rows) => {
        for (let i = 1; i < rows.length; i++) {
          let row = rows[i];

          let bin = row[binPosition];

          if (bin && !this.item.bins.find((item) => item.bin == bin)) {
            this.item.bins.push({ bin: bin.toString().substring(0, 6) });
          }
        }

        this.matchDialog = false;
      });
    },
    setExcelFile(e) {
      if (e.target.files[0]) {
        this.file = e.target.files[0];
        this.matchDialog = true;
      }
    },
    removeBin(e) {
      this.item.bins = this.item.bins.filter((item) => item.bin != e.bin);
    },
    addBin() {
      if (this.bin.toString().length == 6) {
        if (!this.item.bins.find((item) => item.bin == this.bin)) {
          this.item.bins.push({
            bin: this.bin,
          });
          this.bin = "";
        } else {
          this.bin = "";
        }
      } else {
        this.snackbarText = "El BIN debe tener una longitud de 6 dígitos.";
        this.snackbar = true;
      }
    },
    maxLength(evt, field) {
      if (field.toString().length >= 6) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.$binding("banks", db.collection("banks")).then((resp) => {
      this.loading = false;
    });
  },
};
</script>

<style scoped>
.control-input {
  border-color: rgba(117, 117, 117, 0.582);
}

.input {
  display: none;
}
</style>
