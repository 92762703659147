<template>
  <v-row>
    <!-- <v-col cols="12" sm="11" md="11">
      <v-select
        v-model="selectedCity"
        :items="cities"
        placeholder="Escoge la ciudad"
        @change="getCategories"
        outlined
        item-text="name"
        item-value=".key"
        rounded
      />
    </v-col> -->
    <v-col v-if="loading" cols="12" sm="12" md="12">
      <v-row>
        <v-col cols="12" md="6">
          <v-skeleton-loader
            ref="skeleton"
            type="image"
            class="mx-auto d-none d-md-block"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="6">
          <v-skeleton-loader
            ref="skeleton"
            type="image"
            class="mx-auto d-none d-md-block"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else cols="12" sm="12" md="12">
      <a-transfer
        :listStyle="{
          height: '400px!important',
          width: '45%',
          background: 'white',
        }"
        :data-source="categories"
        :titles="['Todas las opciones', 'Opciones vinculadas']"
        :target-keys="optionsSelected"
        :selected-keys="selectedKeys"
        :show-search="true"
        @search="handleSearch"
        :render="(item) => item.title"
        :filter-option="filterOption"
        @change="handleChange"
        @selectChange="handleSelectChange"
        :locale="{
          itemUnit: '',
          itemsUnit: '',
          notFoundContent: 'Lista vacía',
          searchPlaceholder: 'Buscar sección',
        }"
      />
    </v-col>
  </v-row>
  <!-- </v-col> -->
  <!-- </v-container> -->
</template>

<script>
import transfer from "ant-design-vue/lib/transfer";
import { db } from "@/firebase";

export default {
  props: ["item"],
  components: {
    "a-transfer": transfer,
  },
  data() {
    return {
      loading: true,
      categories: [],
      selectedKeys: [],
      optionsSelected: [],
      cities: [],
      selectedCity: "",
      citiesNames: {},
    };
  },
  computed: {
    sortedCategories() {
      function compare(a, b) {
        if (a.title < b.title) return -1;
        if (a.title > b.title) return 1;
        return 0;
      }

      return this.categories.sort(compare);
    },
  },
  methods: {
    handleChange(nextTargetKeys) {
      this.item.categories = nextTargetKeys;
      this.optionsSelected = nextTargetKeys;
      // this.$emit("success", this.optionsSelected);
    },
    handleSearch(value) {
      return value.toUpperCase();
    },
    handleSelectChange(sourceSelectedKeys, targetSelectedKeys) {
      this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys];
    },
    filterOption(inputValue, option) {
      return option.title.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;
    },
    // getCategories(e) {
    //   // if (!e) {
    //   //   return;
    //   // }

    // },
  },

  async mounted() {
    this.loading = true;

    await this.$binding(
      "cities",
      db
        .collection("cities")
        .where("deleted", "==", false)
        .orderBy("name", "asc")
    );

    this.cities.forEach((e) => {
      this.citiesNames[e[".key"]] = e.name;
    });

    this.optionsSelected = [];

    await this.$binding(
      "categories",
      db
        .collection("categories")
        .where("deleted", "==", false)
        // .where("active", "==", true)
    );

    this.categories = this.categories.map((item) => {
      return {
        title: item.name ? `${item.name} - ${this.citiesNames[item.city]}` : "",
        key: item[".key"],
      };
    });

    console.log(this.categories);

    this.optionsSelected = this.item.categories;
    this.categories = this.sortedCategories;
    this.loading = false;

    // this.$binding(
    //   "categories",
    //   db
    //     .collection("categories")
    //     .where("city", "==", this.city)
    //     .where("deleted", "==", false)
    // ).then((categories) => {
    //   this.categories = categories.map((item) => {
    //     return {
    //       title: item.name ? item.name : "",
    //       key: item[".key"],
    //     };
    //   });
    //   this.categories = this.sortedCategories;
    //   this.loading = false;
    // });

    // this.optionsSelected = this.item.categories;
  },
};
</script>
