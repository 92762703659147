<template>
  <v-row>
    <v-col cols="12" sm="12" md="12">
      <v-select
        v-model="selectedBank"
        :items="sortedBanks"
        placeholder="Seleccione un banco"
        multiple
        outlined
        item-text="name"
        @change="getCards"
        :disabled="loading"
        chips
        item-value=".key"
        rounded
      />
    </v-col>

    <v-col v-if="loading" cols="12" sm="12">
      <v-row>
        <v-col cols="12" md="6">
          <v-skeleton-loader
            ref="skeleton"
            type="image"
            class="mx-auto d-none d-md-block mt-5"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="6">
          <v-skeleton-loader
            ref="skeleton"
            type="image"
            class="mx-auto d-none d-md-block mt-5"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-col>

    <v-col v-else cols="12" sm="12" md="12">
      <a-transfer
        :listStyle="{
          height: '400px!important',
          width: '45%',
          background: 'white',
        }"
        :data-source="cards"
        :titles="['Todas las opciones', 'Opciones vinculadas']"
        :target-keys="optionsSelected"
        :selected-keys="selectedKeys"
        :show-search="true"
        @search="handleSearch"
        :render="(item) => item.title"
        :filter-option="filterOption"
        @change="handleChange"
        @selectChange="handleSelectChange"
        :locale="{
          itemUnit: '',
          itemsUnit: '',
          notFoundContent: 'Lista vacía',
          searchPlaceholder: 'Buscar sección',
        }"
      />
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { db } from "@/firebase";
import transfer from "ant-design-vue/lib/transfer";

export default {
  name: "card-bins",
  props: ["item"],
  components: {
    "a-transfer": transfer,
  },
  data() {
    return {
      cards: [],
      selectedKeys: [],
      optionsSelected: [],
      loading: true,
      banks: [],
      selectedBank: "",
    };
  },

  computed: {
    sortedBanks() {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }

      return this.banks.sort(compare);
    },
  },

  methods: {
    handleChange(nextTargetKeys) {
      this.item.bins = nextTargetKeys;
      this.optionsSelected = nextTargetKeys;
    },
    handleSearch(value) {
      return value.toUpperCase();
    },
    handleSelectChange(sourceSelectedKeys, targetSelectedKeys) {
      this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys];
    },
    filterOption(inputValue, option) {
      return option.title.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;
    },

    async getCards(e) {
      if (!e) {
        return;
      }
      this.selectedKeys = [];
      this.cards = [];
      this.loading = true;

      for (let i = 0; i < e.length; i++) {
        const id = e[i];
        await db
          .collection("banks")
          .doc(id)
          .collection("cards")
          .get()
          .then((result) => {
            let format = result.docs.map((item) => {
              let card = item.data();
              return {
                title: `${card.bin} - ${card.brand} - ${card.type}`,
                key: `${card.bin}`,
              };
            });

            this.cards.push(...format);
          });
        this.loading = false;
      }
    },
  },
  async mounted() {
    this.$binding("banks", db.collection("banks")).then((resp) => {
      this.loading = false;
    });
  },
};
</script>


<style scoped>
.divider {
  margin-top: 20px;
  margin-bottom: 20px;
}

.fa-random {
  cursor: pointer;
  /* background-color: gray; */
  /* color: white; */
  font-size: 25px;
  color: gray;
  /* padding: 10px; */
}
</style>